import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import TaskStatusInput from './TaskStatusInput';
import Editor from './TaskContentEditor';
import { observer } from 'mobx-react-lite';
import Task from '../models/task';
import TaskTitleEditor from './TaskTitleEditor';

interface Args {
  task: Task;
  showParentLink?: boolean;
  autofocus?: boolean;

  onFocus?: () => void;
}

function TaskForm({
  task,
  showParentLink = true,
  autofocus = false,
  onFocus,
}: Args) {
  return (
    <div className='w-full h-full'>
      {(task.parent && showParentLink) &&
        <div>
          <Link href={`/${task.parent.id}`}>
            <a className='flex flex-row items-center no-underline'>
              {task.parent.title}
              <ChevronRightIcon className='w-4 h-4' />
            </a>
          </Link>
        </div>
      }
      <div className='flex flex-row items-center border-b pb-4 w-full'>
        <TaskStatusInput task={task} className='w-6 h-6' />
        <TaskTitleEditor task={task} />
      </div>
      <div className='pt-4 pb-6'>
        <Editor
          task={task}
          autofocus={autofocus}
          onFocus={() => onFocus && onFocus()}
        />
      </div>
    </div>
  );
}

export default observer(TaskForm);