import { FolderOpenIcon } from '@heroicons/react/outline';
import React from 'react';
import LocalForageBackend from '../../backends/localforage';
import { tasksFromJSON } from '../../models/task';
import { appstore } from '../../stores/appstore';
import { resetState } from './NewButton';

function OpenButton() {
  return (
    <a
      className='flex h-full w-full cursor-pointer content-center items-center'
      title='Open'
      onClick={async () => {
        const fileBackend = appstore.createFileBackend();

        let app: any;
        let objects: any;
        try {
          const data = await fileBackend.load();
          app = data.app;
          objects = data.objects;
        } catch (e) {
          return;
        }

        appstore.setInitialized(false);
        try {
          await resetState();
        } catch(e) {
          appstore.setInitialized(true);
          return;
        }

        // Hydrate the stores and local storage with the data
        appstore.fromJSON(app);
        tasksFromJSON(objects);

        // Set up the new file backend
        appstore.setFileBackend(fileBackend);
        appstore.refreshFileProperties();

        // Set up the new local backend
        const localBackend = new LocalForageBackend();
        appstore.setLocalforageBackend(localBackend);
        await localBackend.save();

        appstore.setInitialized(true);
      }}
    >
      <FolderOpenIcon className='h-6 w-6 text-slate-400 m-auto' />
    </a>
  );
}

export default OpenButton;