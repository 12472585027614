import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import Task, { taskAncestors } from '../../models/task';
import { appstore } from '../../stores/appstore';
import TaskForm from '../TaskForm';

const pageClasses = 'transform prose w-[33%] my-16 p-8 rounded drop-shadow-lg overflow-y-auto flex';

interface ColumnArgs {
  task: Task;
}

function Column({ task }: ColumnArgs) {
  const container = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={container}
      className={`${pageClasses} min-w-[44rem] snap-center bg-white mx-auto`}
      data-test-column-editor
    >
      <TaskForm
        autofocus={task.id === appstore.task!.id}
        task={task}
        showParentLink={false}
      />
    </div>
  )
}

function ColumnView() {
  const container = useRef<HTMLDivElement>(null);

  const tasks = [appstore.task].concat(taskAncestors(appstore.task));
  return (
    <div
      ref={container}
      className='h-screen w-full overflow-y-hidden overflow-x-auto bg-slate-100 flex flex-row items-stretch pr-4 pl-8 snap-mandatory snap-x gap-6'
    >
      <div className={`${tasks.length > 1 ? 'min-w-[44rem]' : ''} ${pageClasses}`}></div>
      {tasks.map(task => (
        <Column
          key={task.id}
          task={task}
        />
      ))}
      <div className={`${tasks.length > 1 ? 'min-w-[44rem]' : ''} ${pageClasses}`}></div>
    </div>
  );
}

export default observer(ColumnView);