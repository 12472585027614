import React from 'react';
import { appstore } from '../../stores/appstore';
import PersistenceMenu from './PersistenceMenu';
import Search from './Search';
import ViewToggle from './ViewToggle';

function Toolbar() {
  return (
    <div data-test-toolbar className='w-full top-0 fixed flex flex-row items-center justify-center py-2 toolbar'>
      <div className='flex flex-col w-full items-center'>
        <PersistenceMenu />
        {(appstore.rootId === '_root' && !appstore.fileBackend) && (
          <div className='bg-green-100 rounded border border-green-200 px-2 py-1'>
            <p className='text-green-600 text-xs'>You are viewing the Flowpilot project. Changes are only seen by you. To create your own, click the dropdown button above.</p>
          </div>
        )}
      </div>

      <div className="absolute top-0 right-0 py-2 pr-6 flex flex-row">
        <Search />
        <ViewToggle />
      </div>
    </div>
  );
}

export default Toolbar;