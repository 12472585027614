import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import Task, { getTask, taskAncestors, taskDescendants } from '../../models/task';
import * as d3 from 'd3';
import { appstore, View } from '../../stores/appstore';
import { taskStatusColor } from '../TaskStatusInput/StatusIcon';

function CanvasView() {
  const container = useRef<HTMLDivElement>(null);
  const [showComplete, setShowComplete] = useState(false);
  const [nodeWidth, setNodeWidth] = useState(1050);
  const [nodeHeight, setNodeHeight] = useState(100);

  function drawTree(
    data: any[],
    width: number,
    height: number,
    nodeWidth = 400,
    nodeHeight = 200,
    strokeWidth = 2,
    strokeOpacity = 0.5,
    strokeLinejoin = null,
    strokeLinecap = null,
    halo = '#fff',
    haloWidth = 5,
    r = 5,
    fontSize = 20
  ) {

    const root = d3.hierarchy(d3.stratify()(data));

    // Compute labels and titles.
    const descendants = root.descendants();
    const L = descendants.map(d => (d.data.data as Task).title);

    // Compute the layout.
    d3.tree().nodeSize([nodeHeight, nodeWidth])(root);
    const origin = [-width + (nodeWidth / 2), -height / 2, width, height]

    const svg = d3.create('svg')
        .attr('viewBox', origin)
        .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
        .attr('font-size', fontSize);

    const container = svg.append('g');

    container.append('g')
        .attr('fill', 'none')
        .attr('stroke-opacity', strokeOpacity)
        .attr('stroke-linecap', strokeLinecap)
        .attr('stroke-linejoin', strokeLinejoin)
        .attr('stroke-width', strokeWidth)
      .selectAll('path')
        .data(root.links())
        .join('path')
          .attr('stroke', ({ target }) => taskStatusColor((target as any).data.data.status))
          .attr('d', d3.linkHorizontal()
              .x((d: any) => -d.y)
              .y((d: any) => d.x) as any);

    const node = container.append('g')
      .selectAll('a')
      .data(root.descendants())
      .join('a')
        .attr('transform', (d: any) => {
          // Center the viewport on the current node
          if (d.data && d.data.id === appstore.taskId) {
            svg.attr('viewBox', [-d.y - (width / 2), d.x - (height / 2), width, height]);
          }
          return `translate(-${d.y},${d.x})`;
        })
        .attr('data-id', (d: any) => d.data.id)
        .attr('xlink:href', (d: any) => `/${d.data.id}`)
        .attr('class', 'cursor-pointer')
        .on('click', (e) => {
          e.preventDefault();
          const id = e.target.parentElement.dataset.id;
          appstore.setTaskId(id);
          appstore.setView(View.COLUMN)
        });

    node.append('circle')
        .attr('fill', d => taskStatusColor((d as any).data.data.status))
        .attr('r', r);

    node.append('title')
        .text(d => (d.data.data as Task).title);

    if (L) node.append('text')
        .attr('y', '0.28em')
        .attr('x', '0.50em')
        .attr('text-anchor', 'start')
        .text((d, i) => L[i])
        .call(text => text.clone(true))
        .attr('fill', 'none')
        .attr('stroke', halo)
        .attr('stroke-width', haloWidth);

    svg.call(
      d3.zoom()
        .scaleExtent([0.01, 8])
        .on('zoom', ({ transform }) => {
          container.attr("transform", transform.toString());
        }) as any
    )
    return svg.node();
  }

  useEffect(() => {
    if (!appstore.rootId) return;

    let svg: SVGSVGElement | null;
    const root = getTask(appstore.rootId);
    if (container.current && root) {
      let tasks: Task[];
      if (showComplete) {
        tasks = [root, ...taskDescendants(root)];
      } else {
        tasks = [root, ...taskDescendants(root).filter(t => !t.isPruned && !taskAncestors(t).some(t => t.isPruned))];
      }

      svg = drawTree(
        tasks,
        container.current.clientWidth,
        container.current.clientHeight,
        nodeWidth,
        nodeHeight
      );

      if (svg) {
        container.current.appendChild(svg);
      }
    }

    return () => {
      if (container.current && svg) {
        svg.remove();
      }
    }
  }, [container.current, nodeHeight, nodeWidth, showComplete, appstore.taskId])

  return (
    <div
      ref={container}
      className='min-h-screen max-h-screen h-screen min-w-screen max-w-screen w-screen tree-container bg-slate-100'
    >
      <div className='absolute bottom-0 right-2 flex flex-col content-stretch'>
        <label className='my-2 flex flex-row items-center'>
          Show Complete:
          <input className='ml-2' type='checkbox' checked={showComplete} onChange={(e) => setShowComplete(e.target.checked)} />
        </label>
        <label className='my-2 flex flex-row items-center'>
          Width:
          <input className='ml-2 flex-1' type='range' min='100' max='2000' value={nodeWidth} onChange={(e) => setNodeWidth(parseInt(e.target.value))} />
        </label>
        <label className='my-2 flex flex-row items-center'>
          Height:
          <input className='ml-2 flex-1' type='range' min='20' max='180' value={nodeHeight} onChange={(e) => setNodeHeight(parseInt(e.target.value))} />
        </label>
      </div>
    </div>
  );
}

export default observer(CanvasView);