import React from 'react';
import {
  ViewBoardsIcon as ColumnIconSolid,
  ShareIcon as CanvasIconSolid,
} from "@heroicons/react/solid";
import {
  ViewBoardsIcon as ColumnIconOutline,
  ShareIcon as CanvasIconOutline,
} from "@heroicons/react/outline";
import { appstore, View } from "../../stores/appstore";
import { observer } from 'mobx-react-lite';

function ViewToggle() {
  return (
    <div className='flex flex-row'>
      <a
        className={`cursor-pointer mr-2 p-2 rounded ${appstore.view === View.COLUMN ? 'border bg-white' : ''}`}
        onClick={() => appstore.setView(View.COLUMN)}
      >
        {appstore.view === View.COLUMN
          ? <ColumnIconSolid className='h-4 w-4 text-slate-400' />
          : <ColumnIconOutline className='h-4 w-4 text-slate-400' /> }
      </a>
      <a
        className={`cursor-pointer p-2 rounded ${appstore.view === View.CANVAS ? 'border bg-white' : ''}`}
        onClick={() => appstore.setView(View.CANVAS)}
      >
        {appstore.view === View.CANVAS
          ? <CanvasIconSolid className='h-4 w-4 text-slate-400 rotate-90' />
          : <CanvasIconOutline className='h-4 w-4 text-slate-400 rotate-90' /> }
      </a>
    </div>
  )
}

export default observer(ViewToggle);