import React, { useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Task, { updateContentTaskTitle } from '../models/task';
import { editorstore } from '../stores/editorstore';
import { observer } from 'mobx-react-lite';
import Placeholder from '@tiptap/extension-placeholder';

interface Args {
  task: Task;
  autofocus?: boolean;
  onFocus?: () => void;
}

const ParagraphDocument = Document.extend({
  content: 'paragraph',
})

function TaskTitleEditor({ task, onFocus, autofocus }: Args) {
  const editor = useEditor({
    autofocus,
    content: `<p>${task.title}</p>`,
    editorProps: {
      attributes: {
        class: 'prose-2xl focus:outline-none',
      },
    },
    extensions: [
      ParagraphDocument,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: 'What do you want to have done?',
      })
    ],
    onFocus: () => onFocus && onFocus(),
    onUpdate: ({ editor }) => {
      const value = editor.getText()
      task.title = value;
      task.save();
      if (task.parent) {
        updateContentTaskTitle(task.parent, task.id, value);
      }
    },
  });

  useEffect(() => {
    const value = editor?.getText();
    if (editor && task.title !== value) {
      editor.commands.setContent(`<p>${task.title}</p>`);
    }
  }, [task.title]);

  useEffect(() => {
    if (editor) {
      editorstore.add(`title_${task.id}`, editor);
    }
    return () => {
      editorstore.remove(`title_${task.id}`);
    }
  }, [task.id, editor])

  return (
    <EditorContent
      className='w-full py-2 px-2 ml-2 bg-inherit'
      editor={editor}
      data-id={task.id}
    />
  );
}

export default observer(TaskTitleEditor);