import { observer } from 'mobx-react-lite';
import type { NextPage } from 'next'
import React, { useEffect, useState } from "react";

import TaskView from '../components/Views/TaskView';
import { appstore, View } from '../stores/appstore';
import { pool } from '../stores/objectstore';
import ColumnView from '../components/Views/ColumnView';
import CanvasView from '../components/Views/CanvasView';
import Toolbar from '../components/Toolbar';
import Task, { taskContentText, tasksFromJSON } from '../models/task';
import { resetState } from '../components/Toolbar/NewButton';
import LocalForageBackend from '../backends/localforage';
import { migrate } from '../migrations';
import { toJS } from 'mobx';

const App: NextPage = () => {
  const [showWelcome, setShowWelcome] = useState(true);

  let isFresh = false;
  if (pool.objects.length === 1) {
    const task = pool.objects[0];
    if (task && !task.title && !taskContentText(task as Task)) {
      isFresh = true;
    }
  }

  return (
    <div className='h-full w-full'>
      {(appstore.view === View.TASK) ? (
          <TaskView />
        ) : (appstore.view === View.COLUMN) ? (
          <ColumnView />
        ) : (appstore.view === View.CANVAS) ? (
          <CanvasView />
        ) : (
          <p>Something went wrong.</p>
        )
      }

      <Toolbar />

      {(isFresh && showWelcome) && (
        <div
          data-test-welcome
          className='fixed bottom-0 right-0 m-4 bg-white drop-shadow-lg rounded py-4 px-8 max-w-sm'
        >
          <p className='mt-2 mb-4 text-md font-bold'>Welcome</p>
          <p className='mt-2 mb-4 text-sm'>This is Flowpilot, a new way to organize your projects. Feel free to play around. If you want to explore an example, press the button below.</p>
          <div className='flex flex-row justify-between'>
            <button
              className='p-2 rounded text-xs bg-green-400 hover:bg-green-500 text-white'
              onClick={async () => {
                let app: any;
                let objects: any;
                try {
                  const response = await fetch('https://www.flowpilot.co/flowpilot.flowpilot.json');
                  const data = await response.json();
                  const state = migrate(data);
                  app = state.app;
                  objects = state.objects;
                } catch (e) {
                  return;
                }

                appstore.setInitialized(false);
                try {
                  await resetState();
                } catch(e) {
                  appstore.setInitialized(true);
                  return;
                }

                // Hydrate the stores and local storage with the data
                appstore.fromJSON(app);
                tasksFromJSON(objects);

                // Set up the new local backend
                const localBackend = new LocalForageBackend();
                appstore.setLocalforageBackend(localBackend);
                await localBackend.save();

                appstore.setInitialized(true);
              }}
            >
              Let&#39;s go!
            </button>
            <button
              className='border p-2 rounded text-xs'
              onClick={() => {
                setShowWelcome(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {appstore.updateAvailable && (
        <div className={`fixed bottom-0 right-0 m-4 bg-white drop-shadow-lg rounded py-4 px-8 text-right`}>
          <p className='mt-2 mb-4 text-sm'>A new version of Flowpilot is available</p>
          <button
            className='border p-2 rounded text-xs'
            onClick={() => {
              if (!appstore.fileBackend) {
                const confirm = window.confirm('This will overwrite any unsaved changes. Continue?')
                if (!confirm) {
                  return
                }
              }
              appstore.runtime.ipcRenderer.invoke('relaunch');
            }}
          >
            Update & Restart
          </button>
        </div>
      )}
    </div>
  );
}

export default observer(App);
