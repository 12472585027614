import { DocumentAddIcon } from '@heroicons/react/outline';
import React from 'react';
import LocalForageBackend from '../../backends/localforage';
import Task from '../../models/task';
import { appstore } from '../../stores/appstore';
import { pool } from '../../stores/objectstore';

export async function resetState() {
  if (!appstore.fileBackend) {
    const confirm = window.confirm('This will overwrite any unsaved changes. Continue?')
    if (!confirm) {
      throw new Error('Canceled');
    }
  }

  if (appstore.localforageBackend) {
    await appstore.localforageBackend.clear();
  }

  if (appstore.fileBackend) {
    await appstore.fileBackend.clear();
  }

  pool.clear();
  appstore.clear();
}

function NewButton() {
  return (
    <a
      className='flex p-2 h-full w-full cursor-pointer content-center items-center'
      title='Create New'
      onClick={async () => {
        appstore.setInitialized(false);
        try {
          await resetState();
        } catch (e) {
          appstore.setInitialized(true);
          return
        }

        const root = new Task();
        appstore.setTaskId(root.id);
        appstore.setRootId(root.id);

        // Set up the new local backend
        const localBackend = new LocalForageBackend();
        appstore.setLocalforageBackend(localBackend);
        await localBackend.save();

        appstore.setInitialized(true);
      }}
    >
      <DocumentAddIcon className='h-6 w-6 text-slate-400 m-auto' />
    </a>
  );
}

export default NewButton