import { SearchIcon, XIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import Task, { searchTasks } from '../../models/task';
import { appstore, View } from '../../stores/appstore';

function Results({ results, onSelect }: { results: Task[], onSelect: (task: Task) => void }) {
  const [selected, setSelected] = useState(0);

  function selectResult(result: Task) {
    onSelect(result);
  }

  const handler = useCallback((e) => {
    if (e.key === 'ArrowUp') {
      setSelected(((selected + results.length) - 1) % results.length);
      return true;
    } else if (e.key === 'ArrowDown') {
      setSelected((selected + 1) % results.length);
      return true;
    } else if (e.key === 'Enter') {
      selectResult(results[selected]);
      return true;
    }
    return false;
  }, [selected, setSelected, selectResult]);

  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [handler]);

  return (
    <div className='relative border shadow-lg flex flex-col rounded w-full max-h-72 w-64 mr-4 overflow-y-auto overflow-x-hidden'>
      {results.map((i, idx) => (
        <button
          key={i.id}
          className={`${selected === idx ? 'bg-slate-50' : 'bg-white'} w-full block hover:bg-slate-50 px-4 py-2 text-left`}
          onClick={() => selectResult(i)}
        >
          {i.title}
        </button>
      ))}
    </div>
  );
}

function Search() {
  const [showInput, setShowInput] = useState(false);
  const [query, setQuery] = useState('');

  const dismiss = () => {
    setShowInput(false);
    setQuery('');
  }

  const handler = useCallback((e: KeyboardEvent) => {
    if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      setShowInput(true);
      return true;
    } else if (e.key === 'Escape') {
      setShowInput(false);
    }
    return false;
  }, [setShowInput]);

  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [handler]);

  return (
    <Tippy
      render={() => {
        if (!!showInput) {
          const results = searchTasks(query);
          return (
            <Results
              results={results}
              onSelect={(task: Task) => {
                appstore.setTaskId(task.id);
                appstore.setView(View.COLUMN);
                dismiss();
              }}
            />
          );
        }
        return <></>;
      }}
      visible={showInput}
      onClickOutside={dismiss}
      interactive
      placement='bottom-end'
    >
      <div className='flex flex-row'>
        {showInput
          ? <>
              <input
                type="text"
                className='rounded pl-3 pr-7 border'
                autoFocus
                onChange={(e) => setQuery(e.target.value)}
              />
              <a
                className='relative right-6 top-2 cursor-pointer'
                onClick={() => setShowInput(false)}
              >
                <XIcon className='h-4 w-4 text-slate-400' />
              </a>
            </>
          : <a
              className={`cursor-pointer mr-4 relative top-2 rounded`}
              onClick={() => setShowInput(true)}
            >
              <SearchIcon className='h-4 w-4 text-slate-400' />
            </a>
        }
      </div>
    </Tippy>
  );
}

export default Search;