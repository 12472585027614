import { ChevronDownIcon } from '@heroicons/react/solid';
import { CloudIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { appstore } from '../../stores/appstore';
import NewButton from './NewButton';
import OpenButton from './OpenButton';

function PersistenceMenu() {
  const [isVisible, setIsVisible] = useState(false);

  const handleRequestSave = async () => {
    if (appstore.fileBackend) {
      return;
    }

    const fileBackend = appstore.createFileBackend(appstore.filename);
    try {
      await fileBackend.save();
    } catch (e) {
      return
    }
    appstore.setFileBackend(fileBackend);
    appstore.refreshFileProperties();
    setIsVisible(false);
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      handleRequestSave();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <>
      <Tippy
        render={() => (
          <div className={`w-96 min-w-fit border shadow-lg rounded bg-white text-left fixed ${isVisible ? 'visible' : 'hidden'}`}>
            <div className='flex flex-row'>
              <div className='flex flex-1 flex-col border-r'>
                {appstore.fileBackend
                  ? <p className='flex-1 px-4 py-2 prose-sm text-slate-400 italic'>Autosaving to {appstore.filename}</p>
                  : <a
                      onClick={handleRequestSave}
                      className='flex-1 cursor-pointer px-4 py-2 hover:bg-slate-200 text-slate-400'
                    >
                      Save as...
                    </a>
                }
                <div className='flex-1 flex flex-row border-t'>
                  <div className='flex-1 items-center content-center flex hover:bg-slate-200 border-r'>
                    <OpenButton />
                  </div>
                  <div className='flex-1 items-center content-center flex hover:bg-slate-200'>
                    <NewButton />
                  </div>
                </div>
              </div>
              <div className='flex flex-col flex-1 justify-center text-center'>
                <p className='flex flex-row justify-center items-center flex-1 prose-md text-slate-400 px-2 pt-2'>
                  <CloudIcon className='mr-2' width={16} height={16} />
                  <span>Flowpilot Cloud</span>
                </p>
                <div className='flex flex-row justify-center pb-3'>
                  <a href='https://www.flowpilot.co/cloud' rel="noreferrer" target='_blank' className='px-2 py-1 border border-slate-400 rounded hover:bg-gray-100 text-slate-400'>
                    More info
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        visible={isVisible}
        onClickOutside={() => setIsVisible(false)}
        interactive
        placement='bottom-start'
      >
        <div>
          <a
            className='cursor-pointer'
            onClick={() => setIsVisible(!isVisible)}
          >
            <div className='flex flex-row items-center cursor-pointer'>
              <p className='text-slate-400 cursor-pointer'>{(appstore.fileBackend && appstore.filename) ? appstore.filename : 'Untitled'}</p>
              <ChevronDownIcon className='h-4 w-4 text-slate-400 cursor-pointer' />
            </div>
          </a>
        </div>
      </Tippy>
    </>
  );
}

export default observer(PersistenceMenu);