import React from 'react';
import { observer } from 'mobx-react-lite';
import TaskForm from '../TaskForm';
import { appstore } from '../../stores/appstore';

function TaskView() {
  return (
    <div className={'mx-auto prose py-16 px-4'}>
      <TaskForm task={appstore.task} />
    </div>
  );
}

export default observer(TaskView);